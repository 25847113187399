import {
  AdminForm,
  BackButton,
  CSVUpload,
  HiddenFiltersConfig,
} from "../components";
import {
  Button,
  Hint,
  Modal,
  Row,
  Tabs,
  Text,
  ViewOnlyList,
} from "app/components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "app/adminApp/settings/components/SettingsCard";
import { badges, colors } from "app/utils/theme";
import { get, snakeCase, startCase } from "lodash";
import {
  rApp,
  rOrganization,
  rRefreshSheetData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import DataSourceAdmin from "./DataSourceAdmin";
import DataSourceFields from "../components/DataSourceFields";
import SettingsSection from "app/adminApp/settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useState } from "react";

const DataSourceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const activeApp = useRecoilValue(rApp);

  const organization = useRecoilValue(rOrganization);

  const [changes, setChanges] = useState(false);

  const { processDynamicText } = useDynamicText();

  // Storing this here so it doesn't need to be reloaded if you go back and forth
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
  });

  // Add Field Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFieldKey, setNewFieldKey] = useState("");
  const [newFieldKeyChanged, setNewFieldKeyChanged] = useState(false);
  const [newLabel, setNewLabel] = useState("");

  const [isConverting, setIsConverting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [csvPreview, setCsvPreview] = useState(null);

  const supabaseProjectId = get(activeApp, "supabase_project_id");

  const frontlyDb = get(organization, "frontly_db");

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const activeSourceData = savedSpreadsheets.find((s) => s.id == id);

  const copySheetToFrontlyDB = () => {
    setIsConverting(true);
    apiRequest
      .post("/copy_sheet_to_frontly_db/", {
        sheet_id: id,
      })
      .then((response) => {
        const newDb = get(response, "data");
        console.log(newDb);
        successNotification("Sheet copied to Frontly DB");
        navigate("/spreadsheet/" + get(newDb, "id"));
        setIsConverting(false);
        setSavedSpreadsheets([...savedSpreadsheets, newDb]);
      });
  };

  const saveChanges = () => {
    successNotification("Saved");
    apiRequest.post("/sheet_management/", activeSourceData);
    setChanges(false);
  };

  const updateActiveSource = (k, v) => {
    setChanges(true);
    setSavedSpreadsheets(
      savedSpreadsheets.map((s) => {
        if (s.id == id) {
          return { ...s, [k]: v };
        }
        return s;
      })
    );
  };

  const addField = () => {
    if (newFieldKey.trim() && newLabel.trim()) {
      setChanges(true);
      setSavedSpreadsheets(
        savedSpreadsheets.map((s) => {
          if (s.id == id) {
            return {
              ...s,
              headers: [...get(s, "headers", []), newFieldKey.trim()],
              field_data: {
                ...get(s, "field_data", {}),
                label: newLabel.trim(),
              },
            };
          }
          return s;
        })
      );

      setNewFieldKey("");
      setNewFieldKeyChanged(false);
      setNewLabel("");
      setIsModalOpen(false);
    }
  };

  // Configure Buttons
  const service = get(activeSourceData, "service", "google");

  let openServiceLink = null;
  let openServiceText = "";

  if (service === "supabase") {
    openServiceLink = `https://supabase.com/dashboard/project/${supabaseProjectId}`;
    openServiceText = "Open In Supabase";
  } else if (service === "airtable") {
    const sheetConfig = get(activeSourceData, ["field_data", "config"], {});
    const baseId = get(sheetConfig, "base_id", null);
    const tableId = get(sheetConfig, "table_id", null);
    openServiceLink = `https://airtable.com/${baseId}/${tableId}`;
    openServiceText = "Open In Airtable";
  } else if (service === "google") {
    openServiceLink = `https://docs.google.com/spreadsheets/d/${get(
      activeSourceData,
      "spreadsheet_id"
    )}/edit#gid=${get(activeSourceData, "tab_id")}`;
    openServiceText = "Open In Google";
  }

  const setRefreshSheetData = useSetRecoilState(rRefreshSheetData);

  const isFrontlyDb = get(activeSourceData, "service") === "frontly_db";
  const recordCount = get(activeSourceData, "record_count", 0);

  let buttons = [
    {
      text: changes ? "Save Changes" : "Changes Saved",
      onClick: saveChanges,
      disabled: !changes,
    },
  ];

  if (isFrontlyDb) {
    buttons = [
      {
        text: "Add Field",
        type: "basic",
        icon: "FiPlus",
        onClick: () => setIsModalOpen(true),
      },
      ...buttons,
    ];
  } else {
    buttons = [
      {
        text: "Refresh Fields",
        onClick: () => setRefreshSheetData({ sheetId: id }),
        type: "basic",
        icon: "FiRefreshCcw",
      },
      {
        text: openServiceText,
        type: "basic",
        icon: "HiArrowTopRightOnSquare",
        onClick: () => window.open(openServiceLink),
      },
      ...buttons,
    ];

    const hasFrontlyDb = get(organization, "enable_frontly_db");

    if (service === "google" && hasFrontlyDb) {
      buttons = [
        {
          text: "Convert To Frontly DB",
          type: "basic",
          icon: "FiCopy",
          isFetching: isConverting,
          onClick: copySheetToFrontlyDB,
        },
        ...buttons,
      ];
    }
  }

  const [csvSampleRowCount, setCsvSampleRowCount] = useState(0);
  const csvRows = get(csvPreview, "rows", []);
  const sampleRow = get(csvRows, csvSampleRowCount, {});
  const displayFields = Object.keys(sampleRow).map((key) => ({
    key,
    label: startCase(key),
    active: true,
    type: "text",
  }));

  const importCsvRecords = () => {
    setIsImporting(true);
    setSavedSpreadsheets(
      savedSpreadsheets.map((s) => {
        if (s.id == id) {
          return {
            ...s,
            record_count: csvRows.length,
            headers: get(csvPreview, "headers", []),
          };
        }
        return s;
      })
    );

    setCsvPreview(null);

    // TODO - Preserve exact field names as labels

    apiRequest
      .post("/import_frontly_db_records/", {
        id: id,
        headers: get(csvPreview, "headers", []),
        records: csvRows,
      })
      .then((response) => {
        setIsImporting(false);
        successNotification("Records imported");
      });
  };

  // Usage metrics for Frontly DB
  const operationCount = get(frontlyDb, "operation_count", 0);
  const operationLimit = get(frontlyDb, "operation_limit", 0);
  const overOperationLimit = operationCount >= operationLimit;
  const rowCount = get(frontlyDb, "row_count", 0);
  const rowLimit = get(frontlyDb, "row_limit", 0);
  const overRowLimit = rowCount >= rowLimit;

  const plan = get(frontlyDb, "plan", "free");

  const [showFrontlyDbUpgrade, setShowFrontlyDbUpgrade] = useState(false);

  const [tab, setTab] = useState("settings");

  return (
    <AdminWrapper>
      {showFrontlyDbUpgrade && (
        <Modal
          minWidth="800px"
          header={{
            title: "Select your Frontly Database Plan",
          }}
          hide={() => setShowFrontlyDbUpgrade(false)}
        >
          <Row gap="15px">
            {plan === "free" && (
              <UpgradeBox>
                <Text
                  data={{
                    text: "Free Tier",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                />
                <Text
                  data={{ text: "1,000 Monthly Operations", fontSize: 16 }}
                />
                <Text data={{ text: "1,000 Records", fontSize: 16 }} />
                <Button
                  data={{
                    text: "Current",
                    margin: "15px 0 0 0",
                    disabled: true,
                    onClick: () => setShowFrontlyDbUpgrade(true),
                  }}
                />
              </UpgradeBox>
            )}
            <UpgradeBox>
              <Text
                data={{
                  text: "Tier 1 - $10/mo",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{ text: "10,000 Monthly Operations", fontSize: 16 }}
              />
              <Text data={{ text: "10,000 Records", fontSize: 16 }} />

              {plan === "tier_1" ? (
                <Button
                  data={{
                    text: "Current",
                    margin: "15px 0 0 0",
                    disabled: true,
                  }}
                />
              ) : (
                <Button
                  data={{
                    text: "Upgrade To Tier 1",
                    margin: "15px 0 0 0",
                    onClick: () =>
                      window.open("https://buy.stripe.com/fZefZSact5pG7GU15H"),
                  }}
                />
              )}
            </UpgradeBox>
            <UpgradeBox>
              <Text
                data={{
                  text: "Tier 2 - $20/mo",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{ text: "20,000 Monthly Operations", fontSize: 16 }}
              />
              <Text data={{ text: "20,000 Records", fontSize: 16 }} />

              {plan === "tier_2" ? (
                <Button
                  data={{
                    text: "Current",
                    margin: "15px 0 0 0",
                    disabled: true,
                  }}
                />
              ) : (
                <Button
                  data={{
                    text: "Upgrade To Tier 2",
                    margin: "15px 0 0 0",
                    onClick: () =>
                      window.open("https://buy.stripe.com/00gfZSckB3hygdqdSs"),
                  }}
                />
              )}
            </UpgradeBox>
          </Row>
        </Modal>
      )}

      {csvPreview && (
        <Modal
          header={{
            title: `Confirm Import (${csvRows.length} records)`,
          }}
          hide={() => setCsvPreview(null)}
          buttons={[
            {
              text: "Cancel",
              onClick: () => setCsvPreview(null),
              type: "basic",
            },
            {
              text: "Import Records",
              icon: "FiUploadCloud",
              onClick: importCsvRecords,
              disabled: !sampleRow,
              isFetching: isImporting,
            },
          ]}
        >
          <Row justifyContent="space-between" alignItems="center">
            <Text
              data={{
                text: `Previewing Record ${csvSampleRowCount + 1} of ${
                  csvRows.length
                }`,
                color: "var(--grey7)",
                margin: "0 0 20px 0",
                fontSize: 20,
                fontWeight: 500,
              }}
            />
            <Row gap="8px" alignItems="center">
              <Button
                data={{
                  icon: "FiArrowLeft",
                  onClick: () => setCsvSampleRowCount(csvSampleRowCount - 1),
                  disabled: csvSampleRowCount === 0,
                  type: "basic",
                }}
              />
              <Button
                data={{
                  icon: "FiArrowRight",
                  onClick: () => setCsvSampleRowCount(csvSampleRowCount + 1),
                  disabled: csvSampleRowCount === csvRows.length - 1,
                  type: "basic",
                }}
              />
            </Row>
          </Row>
          <ViewOnlyList
            gridLayout={true}
            formState={sampleRow}
            displayFields={displayFields}
            processDynamicText={processDynamicText}
          />
        </Modal>
      )}

      <BackButton
        width="180px"
        margin="0 0 20px 0"
        text="Data Sources"
        onClick={() => navigate("/spreadsheets")}
      />

      {tab === "data" && (
        <DataSourceAdmin
          dataSource={activeSourceData}
          rows={rows}
          setRows={setRows}
          pagination={pagination}
          setPagination={setPagination}
          setTab={setTab}
        />
      )}
      {tab === "settings" && (
        <>
          <Row
            alignItems="center"
            justifyContent="space-between"
            gap="30px"
            margin="0 0 30px 0"
          >
            <Text
              data={{
                text: `${get(activeSourceData, "title")}${
                  isFrontlyDb ? ` (${recordCount} records)` : ""
                }`,
                fontSize: 24,
                fontWeight: 600,
                margin: "0 0 10px 0",
              }}
            />

            <Row gap="10px" alignItems="center">
              {buttons.map((b) => (
                <Button data={b} />
              ))}
            </Row>
          </Row>

          {isFrontlyDb && (
            <Tabs
              data={{
                margin: "20px 0 20px 0",
                tabs: [
                  {
                    label: "Settings",
                    active: true,
                    onClick: () => setTab("settings"),
                  },
                  {
                    label: "Manage Data",
                    active: false,
                    onClick: () => setTab("data"),
                  },
                ],
              }}
            />
          )}

          <ColumnsRow>
            <CardsColumn>
              {isFrontlyDb && (
                <SettingsCard
                  label="Database Usage (last 30 days)"
                  description="Note: This usage is from all databases across your organization."
                  articleLink=""
                >
                  <Row gap="15px">
                    <Row gap="5px" alignItems="center">
                      <Text
                        data={{
                          text: "Records",
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      />
                      <Hint
                        hint="The total number of records in your databases"
                        margin="3px 0 0 0"
                      />
                    </Row>
                    <Text
                      data={{
                        fontSize: 18,
                        text: `${rowCount} / ${rowLimit}`,
                        color: overRowLimit ? badges.red : colors.default,
                      }}
                    />
                  </Row>
                  {overRowLimit && (
                    <Text
                      data={{
                        text: "You've reached your Frontly DB free row limit. Please contact support to upgrade your plan.",
                        color: badges.red,
                        fontWeight: 600,
                        margin: "10px 0 0 0",
                      }}
                    />
                  )}
                  <Row gap="15px" margin="15px 0 0 0">
                    <Row gap="5px" alignItems="center">
                      <Text
                        data={{
                          text: "Operations",
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      />
                      <Hint
                        hint="The total number of operations taken on your databases in the past 30 days. Operations include reads, writes, and deletes"
                        margin="3px 0 0 0"
                      />
                    </Row>
                    <Text
                      data={{
                        fontSize: 18,
                        text: `${operationCount} / ${operationLimit}`,
                        color: overOperationLimit ? badges.red : colors.default,
                      }}
                    />
                  </Row>
                  {overOperationLimit && (
                    <Text
                      data={{
                        text: "You've reached your Frontly DB free operations limit. Please contact support to upgrade your plan.",
                        color: badges.red,
                        fontWeight: 600,
                        margin: "10px 0 0 0",
                      }}
                    />
                  )}

                  <Button
                    data={{
                      text: "View Frontly DB Upgrade Options",
                      margin: "15px 0 0 0",
                      onClick: () => setShowFrontlyDbUpgrade(true),
                    }}
                  />
                </SettingsCard>
              )}
              {isFrontlyDb && recordCount === 0 && (
                <SettingsCard
                  label="Import Data"
                  description="Import data from a CSV file to initialize your database (optional)"
                  articleLink=""
                >
                  <CSVUpload onUpload={setCsvPreview} />
                </SettingsCard>
              )}
              <SettingsCard
                label="Fields"
                description="Configure the types, styles, ordering and other settings for your data source fields"
                articleLink=""
              >
                <DataSourceFields setChanges={setChanges} />
              </SettingsCard>
            </CardsColumn>
            <CardsColumn>
              <SettingsCard
                label="Basics"
                description="Configure the basics of your data source"
                articleLink=""
              >
                <AdminForm
                  fields={[
                    {
                      label: "Title",
                      id: "title",
                      componentId: "Input",
                      required: true,
                      value: get(activeSourceData, "title"),
                    },
                    {
                      label: "Allow Public Access",
                      description:
                        "This will allow anyone on the internet to view, edit and delete your sheet data depending on your settings.",
                      id: "allow_public_access",
                      componentId: "Switch",
                      value: get(activeSourceData, "allow_public_access"),
                    },
                  ]}
                  sectionPadding="0px"
                  onChange={(k, v) => updateActiveSource(k, v)}
                />
              </SettingsCard>
              <SettingsCard
                label="Hidden Filters"
                description="Configure hidden data filters applied at the app-level"
                articleLink="https://help.frontly.ai/en/articles/7971258-hidden-filters"
              >
                <HiddenFiltersConfig
                  data={{
                    hideConditionType: true,
                    value: get(activeSourceData, "hidden_filters"),
                    onChange: (newData) =>
                      updateActiveSource("hidden_filters", newData),
                    linkedSpreadsheet: activeSourceData,
                  }}
                />
              </SettingsCard>
            </CardsColumn>
          </ColumnsRow>
        </>
      )}

      {isModalOpen && (
        <Modal
          minWidth="400px"
          hide={() => setIsModalOpen(false)}
          header={{ title: "Add New Field" }}
          buttons={[
            {
              text: "Add",
              onClick: addField,
              disabled: !newFieldKey.trim() || !newLabel.trim(),
            },
          ]}
        >
          <AdminForm
            sectionPadding="0px"
            labelStyle="headingSm"
            fields={[
              {
                id: "label",
                label: "Label",
                componentId: "Input",
                required: true,
                value: newLabel,
                placeholder: "Enter label",
                onChange: (k, value) => {
                  setNewLabel(value);
                  if (!newFieldKeyChanged) {
                    setNewFieldKey(snakeCase(value));
                  }
                },
              },
              {
                id: "fieldKey",
                label: "Field Key",
                componentId: "Input",
                required: true,
                value: newFieldKey,
                placeholder: "Enter field key",
                onChange: (k, value) => {
                  setNewFieldKeyChanged(true);
                  setNewFieldKey(snakeCase(value));
                },
              },
            ]}
          />
        </Modal>
      )}
    </AdminWrapper>
  );
};

export default DataSourceDetails;

const UpgradeBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border: 1px solid var(--grey3);
  border-radius: 10px;
  width: 100%;
`;
